import { describe, Type } from 'envconfig';

// type EnvConfigType = Parameters<typeof describe>[0];

// uncomment /*: EnvConfigType */ to get intellisense for config object when changing it
const config /*: EnvConfigType */ = {
	REACT_APP_FIREBASE_AUTH_EMULATOR_HOST: {
		isOptional: true,
	},
	REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST: {
		isOptional: true,
	},
	REACT_APP_AUTH_PROVIDER: {},
	NODE_ENV: {
		// we need to set this explicitly as it would otherwise be prepended with REACT_APP_
		name: 'NODE_ENV',
	},
	REACT_APP_DEFAULT_LANGUAGE: {
		default: 'de_DE',
	},
	REACT_APP_AUTH_CLIENT_ID: { default: 'cb72e4e5-bca1-474c-9e5f-6f0bb28f9fa8' },
	REACT_APP_CMS_CLIENT_URL_EXT: { default: 'http://localhost:4040' },
	REACT_APP_BRANCH: null,
	REACT_APP_LOGROCKET_PROJECT: null,
	REACT_APP_FIREBASE_CRED_API_KEY: {
		isOptional: true,
		default: null,
		type: Type.STRING,
	},
	REACT_APP_FIREBASE_CRED_AUTH_DOMAIN: null,
	REACT_APP_FIREBASE_CRED_DATABASE_URL: null,
	REACT_APP_FIREBASE_CRED_PROJECT_ID: null,
	REACT_APP_GRAPHQL_URL_EXT: null,
	REACT_APP_RENDERER_EMBEDS_URL_EXT: null,
	REACT_APP_SOPHORA_URL_EXT: null,
	REACT_APP_WEB_CLIENT_URL_EXT: null,
	REACT_APP_WEB_PREVIEW_CLIENT_URL_EXT: null,
	REACT_APP_IMAGE_UPLOAD_URL_EXT: null,
	REACT_APP_GA_TRACKING_CODE: {
		name: 'GA_TRACKING_CODE',
		default: '',
	},
	REACT_APP_ENABLE_STORY_MERGE_FEATURE: {
		name: 'ENABLE_STORY_MERGE_FEATURE',
		isOptional: true,
		default: false,
	},
	REACT_APP_FIREBASE_AUTH_URL_EXT: {
		default: '/firebase/token',
	},
	REACT_APP_BRBILD_URL_EXT: null,
	REACT_APP_ENABLE_IMPORTER_SOPHORA: {
		isOptional: true,
		default: false,
		type: Type.BOOLEAN,
		sanitize: (value) => value === true || value === 'true',
	},
	REACT_APP_ALLOWED_BOARD_EMBEDS: {
		name: 'REACT_APP_ALLOWED_BOARD_EMBEDS',
		type: Type.ARRAY,
		sanitize: (value: string) => {
			return String(value)
				.split(',')
				.map((value) => value.trim());
		},
	},
};

/**
 * this is a little helper to prepend all configuration items that do not already have a `name` property with `REACT_APP_`
 */
for (const key in config) {
	type ConfigKey = keyof typeof config;

	const configItem = config[key as ConfigKey] as { [key: string]: any };

	const environmentVariableName = key.startsWith(`REACT_APP_`) ? key : `REACT_APP_${key}`;

	if (configItem && typeof configItem === 'object' && !('name' in configItem)) {
		configItem.name = environmentVariableName;
	}

	if (configItem === null) {
		config[key as ConfigKey] = { name: environmentVariableName } as never;
	}
}

const environmentConfig = describe<typeof config>(
	config as typeof config,
	(window as any).config || process.env
);

export default environmentConfig;
