export const SUBSCRIPTION_ACTION_UPDATE_LOCK = 'UPDATE_LOCK';

export const DATABASE_SCHEMA_CHANGED = 'DATABASE_SCHEMA_CHANGED';

export const STATUS_REQUEST = 'STATUS_REQUEST';
export const STATUS_ERROR = 'STATUS_ERROR';
export const STATUS_SUCCESS = 'STATUS_SUCCESS';

export const ARTICLE_UPDATED = 'ARTICLE_UPDATED';
export const BOARD_UPDATED = 'BOARD_UPDATED';

export const SORT_PUBL_DESC = 'PUBLICATION_DATE_DESC';
export const SORT_PUBL_ASC = 'PUBLICATION_DATE_ASC';
export const SORT_EDITED_DESC = 'LAST_EDITED_DESC';
export const SORT_EDITED_ASC = 'LAST_EDITED_ASC';

export const LS_UPDATE_LOCKSTATUS = 'LS_UPDATE_LOCKSTATUS';
export const LS_READ_ONLY_MODE = 'LS_READ_ONLY_MODE';
export const LS_LOCK_DIALOG_OTHER_AUTHOR = 'LS_LOCK_DIALOG_OTHER_AUTHOR';
export const LS_LOCK_DIALOG_OUTDATED_VERSION = 'LS_LOCK_DIALOG_OUTDATED_VERSION';
export const LS_LOCK_DIALOG_BROKEN_BY_USER = 'LS_LOCK_DIALOG_BROKEN_BY_USER';
export const LS_LOCK_DIALOG_UPDATED_WHILE_LOCK = 'LS_LOCK_DIALOG_UPDATED_WHILE_LOCK';
export const LS_LOCK_DIALOG_ERROR_DOCUMENT_CHANGED = 'LS_LOCK_DIALOG_ERROR_DOCUMENT_CHANGED';

export const LS_UPDATE_LOCKED_ITEMS = 'LS_UPDATE_LOCKED_ITEMS';
export const LS_REMOVE_LOCKED_ITEM = 'LS_REMOVE_LOCKED_ITEM';
export const LS_RESET_LOCKED_ITEMS = 'LS_RESET_LOCKED_ITEMS';

export const TYPE_ARTICLE = 'ARTICLE';
export const TYPE_BOARD = 'BOARD';
export const TYPE_BOARD_TEASER = 'BOARD_TEASER';
export const TYPE_AUTHOR = 'AUTHOR';
export const GENDER_MALE = 'MALE';
export const GENDER_FEMALE = 'FEMALE';

export const CREATOR_UPDATE = 'creator/CREATOR_UPDATE';
export const CREATOR_RESET = 'creator/CREATOR_RESET';

export const USER_SETTINGS_UPDATE = 'USER_SETTINGS_UPDATE';
export const USER_ADD_FLAG = 'USER_ADD_FLAG';
export const USER_DELETE_FLAG = 'USER_DELETE_FLAG';
export const USER_SECTION_UPDATE_FLAG = 'USER_SECTION_UPDATE_FLAG';
export const USER_SECTION_AUTOFILL_FLAG = 'USER_SECTION_AUTOFILL_FLAG';

export const SOPHORA_SEARCH_UPDATE = 'SOPHORA_SEARCH_UPDATE';
export const SOPHORA_SEARCH_FIRE = 'SOPHORA_SEARCH_FIRE';
export const SOPHORA_FETCH = 'SOPHORA_FETCH';

export const BOARD_BUFFER_VALIDATE = 'BOARD_BUFFER_VALIDATE';
export const BOARD_BUFFER_INITIALIZE = 'BOARD_BUFFER_INITIALIZE';
export const BOARD_BUFFER_INITIALIZE_AND_CLEAN = 'BOARD_BUFFER_INITIALIZE_AND_CLEAN';
export const BOARD_BUFFER_CLEAN = 'BOARD_BUFFER_CLEAN';
export const BOARD_BUFFER_UPDATE = 'BOARD_BUFFER_UPDATE';
export const BOARD_BUFFER_ERROR = 'BOARD_BUFFER_ERROR';
export const BOARD_BUFFER_SOCIAL_MEDIA_ADD = 'BOARD_BUFFER_SOCIAL_MEDIA_ADD';
export const BOARD_BUFFER_SOCIAL_MEDIA_UPDATE = 'BOARD_BUFFER_SOCIAL_MEDIA_UPDATE';
export const BOARD_BUFFER_SOCIAL_MEDIA_DELETE = 'BOARD_BUFFER_SOCIAL_MEDIA_DELETE';
export const BOARD_BUFFER_SECTION_ADD = 'BOARD_BUFFER_SECTION_ADD';
export const BOARD_BUFFER_SECTION_UPDATE = 'BOARD_BUFFER_SECTION_UPDATE';
export const BOARD_BUFFER_SECTION_DELETE = 'BOARD_BUFFER_SECTION_DELETE';
export const BOARD_BUFFER_SECTION_ORDER = 'BOARD_BUFFER_SECTION_ORDER';
export const BOARD_BUFFER_SECTION_REBUILD_ORDER = 'BOARD_BUFFER_SECTION_REBUILD_ORDER';
export const BOARD_BUFFER_SECTION_ITEM_ADD = 'BOARD_BUFFER_SECTION_ITEM_ADD';
export const BOARD_BUFFER_SECTION_ITEM_UPDATE = 'BOARD_BUFFER_SECTION_ITEM_UPDATE';
export const BOARD_BUFFER_SECTION_ITEM_DELETE = 'BOARD_BUFFER_SECTION_ITEM_DELETE';
export const BOARD_BUFFER_SECTION_ITEM_ORDER = 'BOARD_BUFFER_SECTION_ITEM_ORDER';
export const BOARD_BUFFER_PERSIST = 'BOARD_BUFFER_PERSIST';

export const ARTICLE_BUFFER_CLEAN = 'ARTICLE_BUFFER_CLEAN';
export const ARTICLE_BUFFER_INITIALIZE = 'ARTICLE_BUFFER_INITIALIZE';
export const ARTICLE_BUFFER_MODULE_ADD = 'ARTICLE_BUFFER_MODULE_ADD';
export const ARTICLE_BUFFER_MODULE_DELETE = 'ARTICLE_BUFFER_MODULE_DELETE';
export const ARTICLE_BUFFER_MODULE_UPDATE = 'ARTICLE_BUFFER_MODULE_UPDATE';
export const ARTICLE_BUFFER_UPDATE = 'ARTICLE_BUFFER_UPDATE';
export const ARTICLE_BUFFER_UPDATE_TAGS = 'ARTICLE_BUFFER_UPDATE_TAGS';

export const BOARDS_TEASER_BUFFER_INITIALIZE = 'BOARDS_TEASER_BUFFER_INITIALIZE';
export const BOARDS_TEASER_BUFFER_UPDATE = 'BOARDS_TEASER_BUFFER_UPDATE';
export const BOARDS_TEASER_BUFFER_CLEAN = 'BOARDS_TEASER_BUFFER_CLEAN';

export const AM_UPDATE = 'AM_UPDATE';
export const AM_SET = 'AM_SET';
export const AM_TEMPLATE_SELECT = 'AM_TEMPLATE_SELECT';
export const AM_TEMPLATE_ADD = 'AM_TEMPLATE_ADD';
export const AM_TEMPLATE_DELETE = 'AM_TEMPLATE_DELETE';
export const AM_UPDATE_SELECTION = 'AM_UPDATE_SELECTION';

export const AUTHORS_MANAGER_UPDATE_FILTER = 'AUTHORS_MANAGER_UPDATE_FILTER';

export const CLEAR_MATERIAL_BROWSER = 'CLEAR_MATERIAL_BROWSER';
export const ADD_MATERIAL = 'ADD_MATERIAL';
export const DELETE_MATERIAL = 'DELETE_MATERIAL';

export const APP_INITIALIZE = 'APP_INITIALIZE';
export const APP_BREAKPOINT_UPDATE = 'APP_BREAKPOINT_UPDATE';

export const BP_XS = '(max-width: 930px)';
export const BP_S = '(min-width: 931px) and (max-width: 1200px)';
export const BP_M = '(min-width: 1201px) and (max-width: 1400px)';
export const BP_L = '(min-width: 1401px)';

export const BP_ALL = [BP_XS, BP_S, BP_M, BP_L] as const;

export const LOGIN_ACTION = 'auth/LOGIN';
export const LOGOUT_ACTION = 'auth/LOGOUT';

export const BRBILD_LOGIN = 'brbild/BRBILD_LOGIN';

export const STATUS_DRAFT = 'DRAFT';
export const STATUS_REVIEW = 'REVIEW';
export const STATUS_SCHEDULED = 'SCHEDULED';
export const STATUS_DEPUBLISHED = 'DEPUBLISHED';
export const STATUS_PUBLISHED = 'PUBLISHED';
export const STATUS_DELETED = 'DELETED';
export const STATUS_CHANGED = 'CHANGED';
export const STATUS_CHANGE_PUBLICATION_DATE = 'CHANGE_PUBLICATION_DATE';
export const STATUS_CANNOT_PLACE = 'CANNOT_PLACE';
export const STATUS_NOT_WHITELISTED = 'NOT_WHITELISTED';
export const STATUS_ALREADY_ON_THE_LIST = 'ALREADY_ON_THE_LIST';

export const STATUS_ALL = [
	STATUS_DRAFT,
	STATUS_REVIEW,
	STATUS_SCHEDULED,
	STATUS_DEPUBLISHED,
	STATUS_PUBLISHED,
	STATUS_DELETED,
	STATUS_CHANGED,
	STATUS_CHANGE_PUBLICATION_DATE,
	STATUS_CANNOT_PLACE,
	STATUS_NOT_WHITELISTED,
	STATUS_ALREADY_ON_THE_LIST,
] as const;

export const CATEGORY_ALL = 'CATEGORY_ALL';

export const CAPABILITY_FULLY_FEATURED = 'FULLY_FEATURED';
export const CAPABILITY_BASIC = 'BASIC';
export const CAPABILITY_TEXT_ONLY = 'TEXT_ONLY';
export const CAPABILITY_ALL = [
	CAPABILITY_FULLY_FEATURED,
	CAPABILITY_BASIC,
	CAPABILITY_TEXT_ONLY,
] as const;

export const PRIORITY_CRITICAL = 'CRITICAL';
export const PRIORITY_HIGH = 'HIGH';
export const PRIORITY_NORMAL = 'NORMAL';
export const PRIORITY_LOW = 'LOW';
export const PRIORITY_ALL = [
	PRIORITY_LOW,
	PRIORITY_NORMAL,
	PRIORITY_HIGH,
	PRIORITY_CRITICAL,
] as const;

export const MODULE_TYPE_GALLERY = 'GALLERY';
export const MODULE_TYPE_EMBED = 'EMBED';
export const MODULE_TYPE_TEXT = 'TEXT';
export const MODULE_TYPE_AUDIO = 'AUDIO';
export const MODULE_TYPE_VIDEO = 'VIDEO';
export const MODULE_TYPE_IMAGE = 'IMAGE';
export const MODULE_TYPE_VIDEO360 = 'VIDEO360';
export const MODULE_TYPE_LIVESTREAM = 'LIVESTREAM';

export const MODULE_ALL = [
	MODULE_TYPE_TEXT,
	MODULE_TYPE_IMAGE,
	MODULE_TYPE_GALLERY,
	MODULE_TYPE_VIDEO,
	MODULE_TYPE_AUDIO,
	MODULE_TYPE_EMBED,
	MODULE_TYPE_VIDEO360,
	MODULE_TYPE_LIVESTREAM,
] as const;

// Maybe TODO: Remove this constants as embed services now have there own table in the database --Andi
export const EMBED_TYPE_CIVEY = 'civey';
export const EMBED_TYPE_EBU = 'ebu';
export const EMBED_TYPE_FACEBOOK = 'facebook';
export const EMBED_TYPE_OPINARY = 'opinary';
export const EMBED_TYPE_SCRIBBLE = 'scribble';
export const EMBED_TYPE_TABLEAU = 'tableau';
export const EMBED_TYPE_TWITTER = 'twitter';
export const EMBED_TYPE_FACTFOX = 'factfox';
export const EMBED_TYPE_INSTAGRAM = 'instagram';
export const EMBED_TYPE_CUSTOM_BR24 = 'custom-br24';
export const EMBED_TYPE_CUSTOM_BR24SPORT = 'custom-br24sport';
export const EMBED_TYPE_CUSTOM_BRDATA = 'custom-br-data';

export const LOCATION_DISTRICT = 'district';
export const LOCATION_ZIPCODE = 'zipCode';
export const LOCATION_STREET = 'street';
export const LOCATION_STREETNUMBER = 'streetNumber';
export const LOCATION_COUNTRY = 'country';
export const LOCATION_STATE = 'state';
export const LOCATION_CITY = 'city';
export const LOCATION_SUBURB = 'suburb';

export const DISTRICT_MITTELFRANKEN = 'MITTELFRANKEN';
export const DISTRICT_OBERFRANKEN = 'OBERFRANKEN';
export const DISTRICT_UNTERFRANKEN = 'UNTERFRANKEN';
export const DISTRICT_NIEDERBAYERN = 'NIEDERBAYERN';
export const DISTRICT_OBERPFALZ = 'OBERPFALZ';
export const DISTRICT_SCHWABEN = 'SCHWABEN';
export const DISTRICT_OBERBAYERN = 'OBERBAYERN';

export const DISTRICTS_ALL = [
	DISTRICT_MITTELFRANKEN,
	DISTRICT_OBERFRANKEN,
	DISTRICT_UNTERFRANKEN,
	DISTRICT_NIEDERBAYERN,
	DISTRICT_OBERPFALZ,
	DISTRICT_SCHWABEN,
	DISTRICT_OBERBAYERN,
] as const;

export const TAG_STATUS_ACTIVE = 'ACTIVE';
export const TAG_STATUS_RECOMMENDED = 'RECOMMENDED';
export const TAG_STATUS_SUPERSEDED = 'SUPERSEDED';
export const TAG_STATUS_SPECIAL = 'SPECIAL';

export const TAG_STATUS_ALL = [
	TAG_STATUS_ACTIVE,
	TAG_STATUS_RECOMMENDED,
	TAG_STATUS_SUPERSEDED,
	TAG_STATUS_SPECIAL,
] as const;

export const PUSH_NOTIFICATION_NONE = 'NONE';
export const PUSH_NOTIFICATION_SILENT = 'SILENT';
export const PUSH_NOTIFICATION_SOUND = 'SOUND';
export const PUSH_NOTIFICATION_ALL = [
	PUSH_NOTIFICATION_NONE,
	PUSH_NOTIFICATION_SILENT,
	PUSH_NOTIFICATION_SOUND,
] as const;

export const VIDEO_RESOLUTION_LOW = 'HLS_LOW';
export const VIDEO_RESOLUTION_HIGH = 'HLS_HIGH';
export const VIDEO_RESOLUTION_HD = 'FULL_HD';
export const VIDEO_RESOLUTION_UHD = 'UHD';

export const VIDEO_RESOLUTION_ALL = [
	VIDEO_RESOLUTION_LOW,
	VIDEO_RESOLUTION_HIGH,
	VIDEO_RESOLUTION_HD,
	VIDEO_RESOLUTION_UHD,
] as const;

export const COLOR_BOARD_SECTION_1 = '#D20DE2';
export const COLOR_BOARD_SECTION_2 = '#A6A6A6';
export const COLOR_BOARD_SECTION_3 = '#4D4D4D';
export const COLOR_BOARD_SECTION_4 = '#7D7D7D';

export const COLOR_BOARD_SECTION_ALL = [
	COLOR_BOARD_SECTION_1,
	COLOR_BOARD_SECTION_2,
	COLOR_BOARD_SECTION_3,
	COLOR_BOARD_SECTION_4,
] as const;

export const COLOR_TURQUOISE = '#1abc9c';
export const COLOR_GREEN_SEA = '#16a085';
export const COLOR_EMERALD = '#2ecc71';
export const COLOR_NEPHRITIS = '#27ae60';
export const COLOR_BR24 = '#0B9FD8';
export const COLOR_PETER_RIVER = '#3498db';
export const COLOR_BELIZE_HOLE = '#2980b9';
export const COLOR_AMETHYST = '#9b59b6';
export const COLOR_WISTERIA = '#8e44ad';
export const COLOR_WET_ASPHALT = '#34495e';
export const COLOR_MIDNIGHT_BLUE = '#2c3e50';
export const COLOR_SUN_FLOWER = '#f1c40f';
export const COLOR_ORANGE = '#f39c12';
export const COLOR_CARROT = '#e67e22';
export const COLOR_PUMPKIN = '#d35400';
export const COLOR_ALIZARIN = '#e74c3c';
export const COLOR_POMEGRANATE = '#c0392b';
export const COLOR_SILVER = '#bdc3c7';
export const COLOR_CONCRETE = '#95a5a6';
export const COLOR_ASBESTOS = '#7f8c8d';

export const COLOR_ALL = [
	COLOR_TURQUOISE,
	COLOR_GREEN_SEA,
	COLOR_EMERALD,
	COLOR_NEPHRITIS,
	COLOR_BR24,
	COLOR_PETER_RIVER,
	COLOR_BELIZE_HOLE,
	COLOR_AMETHYST,
	COLOR_WISTERIA,
	COLOR_WET_ASPHALT,
	COLOR_MIDNIGHT_BLUE,
	COLOR_SUN_FLOWER,
	COLOR_ORANGE,
	COLOR_CARROT,
	COLOR_PUMPKIN,
	COLOR_ALIZARIN,
	COLOR_POMEGRANATE,
	COLOR_SILVER,
	COLOR_CONCRETE,
	COLOR_ASBESTOS,
] as const;

export const STAGE_TYPE_NONE = 'NONE';
export const STAGE_TYPE_SIDE_BY_SIDE = 'SIDE_BY_SIDE';
export const STAGE_TYPE_CAROUSEL = 'CAROUSEL';

export const DND_BOARD_ARTICLE = 'DND_BOARD_ARTICLE';
export const DND_MODULE_GALLERY_IMAGE = 'DND_MODULE_GALLERY_IMAGE';

export const DND_BOARD_ITEM = 'DND_BOARD_ITEM';
export const DND_BOARD_SECTION = 'DND_BOARD_SECTION';

export const SOCIAL_MEDIA_ACCOUNT_EMAIL = 'EMAIL';
export const SOCIAL_MEDIA_ACCOUNT_FACEBOOK_PAGE = 'FACEBOOK_PAGE';
export const SOCIAL_MEDIA_ACCOUNT_TWITTER = 'TWITTER';
export const SOCIAL_MEDIA_ACCOUNT_INSTAGRAM = 'INSTAGRAM';
export const SOCIAL_MEDIA_ACCOUNT_YOUTUBE = 'YOUTUBE';
export const SOCIAL_MEDIA_ACCOUNT_SPOTIFY = 'SPOTIFY';

export const SOCIAL_MEDIA_ACCOUNT_ALL = [
	SOCIAL_MEDIA_ACCOUNT_EMAIL,
	SOCIAL_MEDIA_ACCOUNT_FACEBOOK_PAGE,
	SOCIAL_MEDIA_ACCOUNT_TWITTER,
	SOCIAL_MEDIA_ACCOUNT_INSTAGRAM,
	SOCIAL_MEDIA_ACCOUNT_YOUTUBE,
	SOCIAL_MEDIA_ACCOUNT_SPOTIFY,
] as const;

export const LOCALLY_CREATED_ID = 'LOCALLY_CREATED_ID';

export const BOARD_TYPE_BOARD = 'BOARD';
export const BOARD_TYPE_TAG_PAGE = 'TAG_PAGE';
export const BOARD_TYPE_ALL = [BOARD_TYPE_BOARD, BOARD_TYPE_TAG_PAGE];

export const SECTION_THEME_LIGHT = 'LIGHT';
export const SECTION_THEME_DARK = 'DARK';

export const SECTION_ADDON_BAYERNKARTE = 'BAYERNKARTE';
export const SECTION_ADDON_RUNDSCHAU_100_SEKUNDEN = 'RUNDSCHAU_100_SEKUNDEN';
export const SECTION_ADDON_B5_NACHRICHTEN = 'B5_NACHRICHTEN';
export const SECTION_ADDON_B5_BOERSE = 'B5_BOERSE';
export const SECTION_ADDON_NONE = 'NONE_ADDON';
export const SECTION_ADDON_ALL = [
	SECTION_ADDON_NONE,
	SECTION_ADDON_BAYERNKARTE,
	SECTION_ADDON_RUNDSCHAU_100_SEKUNDEN,
	SECTION_ADDON_B5_NACHRICHTEN,
	SECTION_ADDON_B5_BOERSE,
] as const;

export const FLAG_WELCOME_MODAL_SEEN = 'WELCOME_MODAL_SEEN';

export const SECTION_SCHEMA_STAGE_CAROUSEL = 'STAGE_CAROUSEL';
export const SECTION_SCHEMA_STAGE_SPLITTED = 'STAGE_SPLITTED';
export const SECTION_SCHEMA_SMALL_3 = 'SMALL_3';
export const SECTION_SCHEMA_RAISED_LEFT_SIMPLE = 'RAISED_LEFT_SIMPLE';
export const SECTION_SCHEMA_RAISED_RIGHT_SIMPLE = 'RAISED_RIGHT_SIMPLE';
export const SECTION_SCHEMA_REGIONAL_4_SIMPLE = 'REGIONAL_4_SIMPLE';
export const SECTION_SCHEMA_REGIONAL_2_BIG_TOP = 'REGIONAL_2_BIG_TOP';
export const SECTION_SCHEMA_REGIONAL_1_BIG_TOP_2_SMALL_BELOW = 'REGIONAL_1_BIG_TOP_2_SMALL_BELOW';
export const SECTION_SCHEMA_REGIONAL_2_SMALL_TOP_1_BIG_BELOW = 'REGIONAL_2_SMALL_TOP_1_BIG_BELOW';
export const SECTION_SCHEMA_HIGHLIGHT_3 = 'HIGHLIGHT_3';
export const SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL = 'CATEGORY_LEFT_2_SMALL';
export const SECTION_SCHEMA_CATEGORY_LEFT_1_BIG = 'CATEGORY_LEFT_1_BIG';
export const SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL = 'CATEGORY_RIGHT_2_SMALL';
export const SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG = 'CATEGORY_RIGHT_1_BIG';
export const SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS = 'RAISED_RIGHT_SHORTNEWS';
export const SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL = 'RAISED_RIGHT_SHORTNEWS_2_SMALL';
export const SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER = 'RAISED_RIGHT_SIMPLE_WEATHER';
export const SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER = 'SUBJECTS_NEWS_WEATHER';
export const SECTION_SCHEMA_LIST_AUTO = "LIST_AUTO";
export const SECTION_SCHEMA_ALL = [
	SECTION_SCHEMA_STAGE_CAROUSEL,
	SECTION_SCHEMA_SMALL_3,
	SECTION_SCHEMA_RAISED_LEFT_SIMPLE,
	SECTION_SCHEMA_RAISED_RIGHT_SIMPLE,
	SECTION_SCHEMA_REGIONAL_4_SIMPLE,
	SECTION_SCHEMA_REGIONAL_2_BIG_TOP,
	SECTION_SCHEMA_REGIONAL_1_BIG_TOP_2_SMALL_BELOW,
	SECTION_SCHEMA_REGIONAL_2_SMALL_TOP_1_BIG_BELOW,
	SECTION_SCHEMA_HIGHLIGHT_3,
	SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL,
	SECTION_SCHEMA_CATEGORY_LEFT_1_BIG,
	SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL,
	SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG,
	SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS,
	SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL,
	SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER,
	SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER,
	SECTION_SCHEMA_LIST_AUTO,
] as const;

export const SECTION_SCHEMA_BOARD = [
	SECTION_SCHEMA_STAGE_CAROUSEL,
	SECTION_SCHEMA_SMALL_3,
	SECTION_SCHEMA_RAISED_LEFT_SIMPLE,
	SECTION_SCHEMA_RAISED_RIGHT_SIMPLE,
	SECTION_SCHEMA_REGIONAL_4_SIMPLE,
	SECTION_SCHEMA_REGIONAL_2_BIG_TOP,
	SECTION_SCHEMA_REGIONAL_1_BIG_TOP_2_SMALL_BELOW,
	SECTION_SCHEMA_REGIONAL_2_SMALL_TOP_1_BIG_BELOW,
	SECTION_SCHEMA_HIGHLIGHT_3,
	SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL,
	SECTION_SCHEMA_CATEGORY_LEFT_1_BIG,
	SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL,
	SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG,
	SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS,
	SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL,
	SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER,
	SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER
];
export const SECTION_SCHEMA_TAG_PAGE = [
	SECTION_SCHEMA_LIST_AUTO
];

export const BOARD_TYPE_SECTION_SCHEMA = {
	[BOARD_TYPE_BOARD]: SECTION_SCHEMA_BOARD,
	[BOARD_TYPE_TAG_PAGE]: SECTION_SCHEMA_TAG_PAGE,
}

// see: https://public.tableau.com/views/verweildauer/Verweildauer-2?%3Aembed=y&amp%3B%3AshowVizHome=no&amp%3B%3Ahost_url=https%3A%2F%2Fpublic.tableau.com%2F&amp%3B%3Atabs=no&amp%3B%3Atoolbar=yes&amp%3B%3Aanimate_transition=yes&amp%3B%3Adisplay_static_image=no&amp%3B%3Adisplay_spinner=no&amp%3B%3Adisplay_overlay=yes&amp%3B%3Adisplay_count=yes&amp%3B%3AloadOrderID=0%22
/**
 *  1 - Programminformationen (EPG) sowie Unternehmensinhalte
 */
export const EXPIRATION_EPG_BUSINESS_CONTENT = 'EPG_BUSINESS_CONTENT';
/**
 *  2 - Wiederkehrende Ereignisse
 */
export const EXPIRATION_REPEATED_EVENTS = 'REPEATED_EVENTS';

/**
 * 3 - Programmbegleitende Inhalte
 */
export const EXPIRATION_SUPPORTING_INFO = 'SUPPORTING_INFO';
/**
 * 4 - Inhalte zu Serien und Mehrteilern
 */
export const EXPIRATION_SERIES = 'SERIES';
/**
 * 5 - Bildungsinhalte
 */
export const EXPIRATION_EDUCATION = 'EDUCATION';

/**
 * **Kategorie 1**: Non-Fiktionale Inhalte
 *   * Nachrichten
 *   * Aktuelle Informationen
 *   * Gesprächsformate
 *   * Magazine
 *   * Dokumentationen
 *   * Dokutainment-Formate
 *   * Reportagen
 *   * Features
 *   * Politisches Kabarett
 *   * Comedy
 *   * Satire
 *   * Show
 */
export const EXPIRATION_NON_FICTIONAL_CONTENT = 'NON_FICTIONAL_CONTENT';

/**
 * **Kategorie 2a**: Fiktionale Inhalte
 *  * Filme
 *  * Hörspiele
 *  * Lesungen
 *  * Mehrteiler
 *  * Reihen
 *  * Serien
 */
export const EXPIRATION_FICTIONAL_CONTENT = 'FICTIONAL_CONTENT';

/**
 * **Kategorie 2b**: Debüt-Filme
 *
 * Die ersten drei Produktionen von Regisseurinnen und Regisseuren, Autorinnen und Autoren
 * und/oder Hauptdarstellerinnen und Hauptdarstellern
 */
export const EXPIRATION_DEBUT_FILM = 'DEBUT_FILM';

/**
 * **Kategorie 2c**: Europäische Lizenzproduktionen
 */
export const EXPIRATION_EUROPEAN_LICENSE_PRODUCTION = 'EUROPEAN_LICENSE_PRODUCTION';

/**
 * **Kategorie 3**: Programmschwerpunkte/Themenschwerpunkte
 */
export const EXPIRATION_PROGRAM_FOCUS = 'PROGRAM_FOCUS';

/**
 * **Kategorie 4**: Inhalte für Kinder
 *  * Kinderspielfilme
 *  * Kinder-Dokumentationen
 *  * Kinderhörspiele
 *  * Märchen
 *  * Kinderserien
 *  * Erklärstücke
 *  * Lehr- und Lerninhalte
 *  * Kindernachrichten
 *  * Kinderunterhaltung
 */
export const EXPIRATION_CONTENT_FOR_KIDS = 'CONTENT_FOR_KIDS';

/**
 * **Kategorie 5**: Bildungsinhalte
 *
 * (Wissenschaft, Technik, Theologie, Ethik, politische Bildung, Umwelt, Arbeit und Soziales,
 *  Kulturdoku- mentationen, Lehr- und Lerninhalte)
 */
export const EXPIRATION_EDUCATIONAL_CONTENT = 'EDUCATIONAL_CONTENT';

/**
 * **Kategorie 6**: Redaktionelle Entscheidung
 *
 * Ausgewählte Inhalte können mit transparent nach- vollziehbarer Begründung eingestellt oder
 * wieder eingestellt bzw. in ein Archiv überführt werden. Beispiele:
 *   * Fortdauernder gesellschaftlicher Diskurs
 *   * Zeit- oder kulturgeschichtliche Gründe
 *   * Programminformationen, Basisinformationen, Wiederholungen oder Inhalte mit Ereignisbezug
 *   * Berichterstattung über Wahlen und andere wiederkehrende Ereignisse
 *   * Aus Anlass eines Ereignisses oder der Berichterstattung darüber
 */
export const EXPIRATION_EDITORIAL_DECISION = 'EDITORIAL_DECISION';

/**
 * **Kategorie 6**: Zeit- und kulturgeschichtliche Archive mit informierenden,
 * bildenden und kulturellen Telemedien
 */
export const EXPIRATION_TIME_CULTURE = 'TIME_CULTURE';

/**
 * Correctly ordered list of expiration values for articles published after July 19 2023.
 *
 * @see https://intranet.br.de/der-br/strategie/neue-verweildauern-intranet-100.html
 */
export const EXPIRATION_ALL_V2 = [
	{
		id: EXPIRATION_NON_FICTIONAL_CONTENT,
		duration: 2,
		units: 'years',
		required: true,
	},
	{
		id: EXPIRATION_EDITORIAL_DECISION,
		duration: Infinity,
		units: 'years',
		required: false,
	},
	{
		id: EXPIRATION_EDUCATIONAL_CONTENT,
		duration: 5,
		units: 'years',
		required: true,
	},
	{
		id: EXPIRATION_FICTIONAL_CONTENT,
		duration: 12,
		units: 'months',
		required: true,
	},
	{
		id: EXPIRATION_DEBUT_FILM,
		duration: 2,
		units: 'years',
		required: true,
	},
	{
		id: EXPIRATION_EUROPEAN_LICENSE_PRODUCTION,
		duration: 30,
		units: 'days',
		required: true,
	},
	{
		id: EXPIRATION_PROGRAM_FOCUS,
		duration: 2,
		units: 'years',
		required: true,
	},
	{
		id: EXPIRATION_CONTENT_FOR_KIDS,
		duration: 5,
		units: 'years',
		required: true,
	},
	{
		id: EXPIRATION_TIME_CULTURE,
		duration: Infinity,
		units: 'years',
		required: false,
	},
] as const;

/**
 * Expiration values for all articles published before July 19 2023
 *
 * The implementation enforces these values only after it has been deployed some time in September 2023
 */
export const EXPIRATION_ALL_V1 = [
	{
		id: EXPIRATION_EPG_BUSINESS_CONTENT,
		duration: Infinity,
		units: 'years',
		required: false,
	},
	{
		id: EXPIRATION_REPEATED_EVENTS,
		duration: Infinity,
		units: 'years',
		required: true,
	},
	{
		id: EXPIRATION_SUPPORTING_INFO,
		duration: 12,
		units: 'months',
		required: true,
	},
	{
		id: EXPIRATION_SERIES,
		duration: 12,
		units: 'months',
		required: true,
	},
	{
		id: EXPIRATION_EDUCATION,
		duration: 5,
		units: 'years',
		required: true,
	},
	{
		id: EXPIRATION_TIME_CULTURE,
		duration: Infinity,
		units: 'years',
		required: false,
	},
] as const;

export const LINKS_DOMAINS_WHITELIST = [
	'*.br.de',
	'*.tagesschau.de',
	'*.sportschau.de',
	'*.bayern3.de',
	'*.br-klassik.de',
	'*.br24.de',
	'*.br-dev.de',
	'*.br-staging.de',
	'*.ard.de',
	'*.ardalpha.de',
	'*.ardmediathek.de',
	'*.ardaudiothek.de',
] as const;
