import * as constants from '@sep/br24-constants/dist/cms';

export default {
	title: 'CMS | br24.de',
	typingInterval: 500,
	articleIcons: {
		[constants.MODULE_TYPE_GALLERY]: 'AM_TYPE_GALLERY',
		[constants.MODULE_TYPE_EMBED]: 'AM_TYPE_EMBED_CODE',
		[constants.MODULE_TYPE_TEXT]: 'AM_TYPE_TEXT',
		[constants.MODULE_TYPE_AUDIO]: 'AM_TYPE_AUDIO',
		[constants.MODULE_TYPE_VIDEO]: 'AM_TYPE_VIDEO',
		[constants.MODULE_TYPE_IMAGE]: 'AM_TYPE_IMAGE',
		[constants.MODULE_TYPE_VIDEO360]: 'AM_TYPE_VIDEO360',
		[constants.MODULE_TYPE_LIVESTREAM]: 'AM_TYPE_LIVESTREAM',
	},
	moduleIcons: {
		[constants.MODULE_TYPE_GALLERY]: 'layout',
		[constants.MODULE_TYPE_EMBED]: 'code-o',
		[constants.MODULE_TYPE_TEXT]: 'file-alt',
		[constants.MODULE_TYPE_AUDIO]: 'sound',
		[constants.MODULE_TYPE_VIDEO]: 'video-camera',
		[constants.MODULE_TYPE_IMAGE]: 'picture',
		[constants.MODULE_TYPE_VIDEO360]: 'camera',
		[constants.MODULE_TYPE_LIVESTREAM]: 'camera-o',
	},
	moduleTitle: {
		[constants.MODULE_TYPE_GALLERY]: 'Galerie',
		[constants.MODULE_TYPE_EMBED]: 'Embed-Code',
		[constants.MODULE_TYPE_TEXT]: 'Text',
		[constants.MODULE_TYPE_AUDIO]: 'Audio',
		[constants.MODULE_TYPE_VIDEO]: 'Video',
		[constants.MODULE_TYPE_IMAGE]: 'Bild',
		[constants.MODULE_TYPE_VIDEO360]: 'Video 360°',
		[constants.MODULE_TYPE_LIVESTREAM]: 'Livestream',
	},
	statusBadgeMap: {
		[constants.STATUS_DRAFT]: 'warning',
		[constants.STATUS_REVIEW]: 'processing',
		[constants.STATUS_SCHEDULED]: 'success',
		[constants.STATUS_DEPUBLISHED]: 'error',
		[constants.STATUS_PUBLISHED]: 'success',
		[constants.STATUS_DELETED]: 'error',
	},
	statusTagColorMap: {
		[constants.STATUS_DRAFT]: constants.COLOR_CARROT,
		[constants.STATUS_REVIEW]: constants.COLOR_BELIZE_HOLE,
		[constants.STATUS_SCHEDULED]: constants.COLOR_GREEN_SEA,
		[constants.STATUS_DEPUBLISHED]: constants.COLOR_ALIZARIN,
		[constants.STATUS_PUBLISHED]: constants.COLOR_NEPHRITIS,
		[constants.STATUS_DELETED]: constants.COLOR_POMEGRANATE,
	},
	statusIconMap: {
		[constants.STATUS_DRAFT]: 'file-text',
		[constants.STATUS_REVIEW]: 'user',
		[constants.STATUS_SCHEDULED]: 'clock-circle-o',
		[constants.STATUS_DEPUBLISHED]: 'exclamation-circle-o',
		[constants.STATUS_PUBLISHED]: 'check',
		[constants.STATUS_DELETED]: 'close',
	},
	articlePriorityMap: {
		[constants.PRIORITY_CRITICAL]: 'error',
		[constants.PRIORITY_HIGH]: 'warning',
		[constants.PRIORITY_NORMAL]: 'default',
		[constants.PRIORITY_LOW]: 'default',
	},
	availableModules: {
		[constants.CAPABILITY_TEXT_ONLY]: [constants.MODULE_TYPE_TEXT],
		[constants.CAPABILITY_BASIC]: [
			constants.MODULE_TYPE_TEXT,
			constants.MODULE_TYPE_IMAGE,
			constants.MODULE_TYPE_GALLERY,
			constants.MODULE_TYPE_VIDEO,
			constants.MODULE_TYPE_AUDIO,
			constants.MODULE_TYPE_VIDEO360,
		],
		[constants.CAPABILITY_FULLY_FEATURED]: constants.MODULE_ALL,
	},
	moduleColors: {
		[constants.MODULE_TYPE_GALLERY]: constants.COLOR_TURQUOISE,
		[constants.MODULE_TYPE_EMBED]: constants.COLOR_NEPHRITIS,
		[constants.MODULE_TYPE_TEXT]: constants.COLOR_PETER_RIVER,
		[constants.MODULE_TYPE_AUDIO]: constants.COLOR_AMETHYST,
		[constants.MODULE_TYPE_VIDEO]: constants.COLOR_WET_ASPHALT,
		[constants.MODULE_TYPE_IMAGE]: constants.COLOR_PUMPKIN,
		[constants.MODULE_TYPE_VIDEO360]: constants.COLOR_POMEGRANATE,
		[constants.MODULE_TYPE_LIVESTREAM]: constants.COLOR_EMERALD,
	},
	regionColors: {
		[constants.DISTRICT_MITTELFRANKEN]: constants.COLOR_TURQUOISE,
		[constants.DISTRICT_OBERFRANKEN]: constants.COLOR_NEPHRITIS,
		[constants.DISTRICT_UNTERFRANKEN]: constants.COLOR_PETER_RIVER,
		[constants.DISTRICT_NIEDERBAYERN]: constants.COLOR_WET_ASPHALT,
		[constants.DISTRICT_OBERPFALZ]: constants.COLOR_POMEGRANATE,
		[constants.DISTRICT_SCHWABEN]: constants.COLOR_EMERALD,
		[constants.DISTRICT_OBERBAYERN]: constants.COLOR_PUMPKIN,
	},
	moment: {
		absolute: 'DD.MM.YYYY HH:mm',
		absoluteDate: 'DD.MM.YYYY',
	},
	sidebarWidth: {
		[constants.BP_XS]: 200,
		[constants.BP_S]: 300,
		[constants.BP_M]: 400,
		[constants.BP_L]: 500,
	},

	breakPointsMinAvailableSize: {
		[constants.BP_XS]: 600,
		[constants.BP_S]: 900,
		[constants.BP_M]: 1200,
		[constants.BP_L]: 1400,
	},
	navigation: {
		disableDeleteForIds: [3],
	},
	articleModules: {
		header: {
			titleMinLength: 3,
			titleMaxLength: 64,
			teasertextMinLength: 3,
			teasertextMaxLength: 250,
		},
		audio: {
			teasertextMinLength: 3,
			teasertextMaxLength: 250,
		},
		gallery: {
			imageThumbnailWidth: 400,
			imageThumbnailHeight: 200,
		},
		video: {
			titleMinLength: 3,
			titleMaxLength: 250,
		},
		image: {
			titleMinLength: 3,
			titleMaxLength: 145,
			preLoadBlur: 200,
			preLoadQuality: 20,
			preLoadFit: 'crop',
			preLoadHeight: 800,
		},
	},
	articleEditor: {
		meta: {
			links: {
				maxLength: 64,
				minLength: 3,
			},
			seo: {
				titleMaxLength: 100,
				descriptionMinLength: 3,
				descriptionMaxLength: 300,
			},
			notes: {
				maxLength: 500,
			},
			tags: {
				maxLength: 50,
			},
		},
	},
	author: {
		firstname: {
			minLength: 1,
			maxLength: 30,
		},
		lastname: {
			minLength: 1,
			maxLength: 30,
		},
		jobTitle: {
			minLength: 3,
			maxLength: 60,
		},
		description: {
			maxLength: 1000,
		},
		linkname: {
			minLength: 3,
			maxLength: 30,
		},
		socialMedia: {
			label: {
				maxLength: 30,
			},
		},
	},
};
